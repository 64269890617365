import React from "react";
import SEO from "../../components/seo";
import { graphql } from "gatsby";

export default ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <main>
      <SEO title={frontmatter.title} />

      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </main>
  );
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "privacy-policy" } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
